<template>
    <b-card title="Activity Log">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-between">
            <b-form-group class="justify-content-end">
                <div class="d-flex align-items-center">
                    <label class="mr-1">Search</label>
                    <b-form-input
                        v-model="searchTerm"
                        placeholder="Search"
                        type="text"
                        class="d-inline-block"
                    />
                </div>
            </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
                enabled: true,
                externalQuery: searchTerm 
            }"
            :pagination-options="{
                enabled: true,
                perPage:pageLength
            }"
            styleClass="vgt-table striped condensed no-outline"
        >
    
            <template
                slot="table-row"
                slot-scope="props"
            >
                
                <!-- Column: Index -->
                <span v-if="props.column.field === 'index'">
                    <span>{{props.row.originalIndex+1}}</span>
                </span>
        
                <!-- Column: Common -->
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
    
            <!-- pagination -->
            <template
                slot="pagination-bottom"
                slot-scope="props"
            >
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                            Showing 1 to
                        </span>
                        <b-form-select
                            v-model="pageLength"
                            :options="['15','20','30']"
                            class="mx-1"
                            @input="(value)=>props.perPageChanged({currentPerPage:value})"
                        />
                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                    </div>
                    <div>
                        <b-pagination
                            :value="1"
                            :total-rows="props.total"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0"
                            @input="(value)=>props.pageChanged({currentPage:value})"
                        >
                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </b-card>
</template>
  
  <script>
  
import {
BAvatar,BButton, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard,
BModal,VBModal,BCardText, BFormTextarea
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import store from '@/store/index';
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import {mapState} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default { 
    components: {
        VueGoodTable,
        BAvatar,
        BCard,
        BBadge,
        BButton,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BModal,
        vSelect,
        BCardText,
        BFormTextarea
    },

    directives: {
        'b-modal': VBModal,
        Ripple
    },

    data() {
        return {
            pageLength: 15,
            dir: false,
            columns: [
                {
                    label: '#',
                    field: 'index',
                    type:'number',
                    tdClass: 'text-center ',
                    thClass:'text-center'
                },
                {
                    label: 'Activity',
                    field: 'activity',
                    tdClass: 'text-center',
                    thClass:'text-center'
                },  
                {
                    label: 'Time & Date',
                    field: 'timestamp',
                    tdClass: 'text-center',
                    thClass:'text-center'
                }  
            ],
            rows: [],
            searchTerm: ''
        }
    },

    computed: {
        ...mapState('users', ["logs","userError","userLoading"]),
    
        direction() {
            if (store.state.appConfig.isRTL) {
                this.dir = true
                return this.dir
            }
            this.dir = false
            return this.dir
        }
    },

    watch: {
        logs(newValue, oldValue) {
            this.rows = this.logs
        }
    },

    created() {
        this.$store.dispatch('users/fetchActivityLogByUser', this.$route.params.id)
        this.rows = this.logs
        let permissions=JSON.parse(localStorage.getItem('permissions'))
    }
}
</script>
  
<style lang="scss" >
.no-outline{
    border:none !important;
}

.f20{
    font-size: 8px !important;
}

.vs__dropdown-menu {
  height: 200px;
}

.activity-content {
    font-size: medium;
    display: flex;
    gap: 1rem;
}

@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

</style>